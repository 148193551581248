<template>
    <h-row justify="center">
        <h-col no-padding>
            <v-img class="rounded-lg"
                   style="margin: auto; border: 1px solid #000000"
                   src="/assets/LOGO_ORIGINAL.png"
                   alt="LOGO"
            ></v-img>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/generics/HRow';
    import HCol from '@/components/generics/HCol';

    export default {
        name: 'Index',
        components: {HRow, HCol}
    }
</script>

<style scoped>

</style>
